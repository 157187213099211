<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">City Payments</div>
            <div class="subtitle-1 font-weight-light">
              A list of all City Payments
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="start"
            >
              <v-col cols="12" md="8" lg="5" class="px-0">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                  sort-by="created_at"
                  :sort-desc="true"
                >
                  <template v-slot:item.userType="{ item }">
                    <span class="text-center subtitle-2 text-capitalize">{{
                      item.userType
                    }}</span>
                  </template>
                  <template v-slot:item.upgrade="{ item }">
                    <!-- {{item.isPaymentApproved}} -->
                    <v-btn
                      :color="getCityApprovalColor(item.isPaymentApproved)"
                      :disabled="item.isPaymentApproved == 0"
                      @click="paymentApprovalFn(item)"
                      class="upgrade-btn"
                    >
                      <span>{{
                        getCityApprovalState(item.isPaymentApproved)
                      }}</span>
                    </v-btn>
                  </template>
                  <template v-slot:item.totalPrice="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span
                          class="text-center subtitle-2 text-capitalize"
                          v-on="on"
                          >€{{ item.totalPrice }}</span
                        >
                      </template>
                      <span class="d-block">Price: €{{ item.price }}</span>
                      <span class="d-block">VAT: €{{ item.vat }}</span>
                      <span class="d-block"
                        >Total Price: €{{ item.totalPrice }}</span
                      >
                    </v-tooltip>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-chip
                      class="ma-2"
                      :color="
                        item.status == 'incomplete'
                          ? 'platinum darken-2'
                          : item.status == 'succeeded'
                          ? 'primary'
                          : 'error'
                      "
                      text-color="white"
                    >
                      <span class="text-center subtitle-2 text-capitalize">{{
                        item.status
                      }}</span>
                      <v-icon right
                        >{{
                          item.status == "incomplete"
                            ? "mdi-clock"
                            : item.status == "succeeded"
                            ? "mdi-check"
                            : "mdi-alert-circle"
                        }}
                      </v-icon>
                    </v-chip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
        <v-dialog v-model="isDialog" scrollable max-width="800px">
          <v-card>
            <v-card-title class="px-2 px-sm-6">
              <v-row class="pb-3">
                <v-col
                  cols="8"
                  lg="10"
                  class="d-flex flex-column justify-center align-start py-0"
                >
                  <h2 class="headline text-left">Proof Verification</h2>
                </v-col>
                <v-col
                  cols="4"
                  lg="2"
                  class="d-flex flex-column justify-center align-end py-0"
                >
                  <v-btn
                    icon
                    color="grey lighten-1"
                    class="mr-0 mb-0"
                    @click="closeDialog"
                  >
                    <v-icon class="dialog-close-icon">mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container class="px-0">
                <v-row class="mx-0">
                  <v-col cols="12" class="px-0">
                    <!-- individual row for event summary details -->
                    <v-row class="px-0">
                      <v-col cols="12" class="mb-1">
                        <h2 class="text-left title">Proof Image:</h2>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="12" class="mb-1">
                        <v-img v-if="proof" :src="proof" />
                        <span
                          class="subtitle-1 text-left platinum--text text--darken-3"
                          v-else
                          >The image has not been uploaded yet</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- <v-row>isAnuval : {{isPricePlanAnnual}}</v-row>
                <v-row>Annually :{{priceAnnually}}</v-row>
                <v-row>Monthly :{{priceMonthly}}</v-row>
                <v-row>{{paymentSchema}}</v-row>-->
                <v-row>
                  <v-col cols="12" class="mb-1">
                    <h2 class="text-left title">Approval State:</h2>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-select
                      label="State"
                      v-model="state"
                      :items="stateList"
                      return-object
                      item-text="str"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="this.isApproved">
                  <v-col cols="12" class="mb-1">
                    <h2 class="text-left title">Next Billing Date:</h2>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-menu
                      ref="nextBillingDateMenu"
                      v-model="nextBillingDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="nextBillingDate"
                          label="Next Billing Date"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        disableDates
                        v-model="nextBillingDate"
                        :min="new Date().toISOString().substr(0, 10)"
                        no-title
                        @input="nextBillingDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="px-6">
              <v-row
                class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0"
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-start px-0"
                >
                  <v-btn
                    class="px-8"
                    color="secondary"
                    @click="closeDialog"
                    tile
                    outlined
                    depressed
                    bottom
                    >Cancel
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-end px-0"
                >
                  <v-btn
                    class="px-8"
                    :loading="isApiLoader"
                    @click="paymentApprovalSubmitFn"
                    color="secondary"
                    tile
                    depressed
                    bottom
                    right
                    >Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransactionsApiCalls from "../../../services/subscribePlane.sevice";

export default {
  name: "DirectPayments",
  data() {
    return {
      search: "",
      isLoading: false,
      deleteDialog: false,
      isDialog: false,
      isApproved: false,
      nextBillingDate: null,
      nextBillingDateMenu: false,
      name: null,
      state: null,
      proof: null,
      payment_Id: null,
      isApiLoader: false,
      stateList: [
        // { str: "≤ 20 km", val: 0 },
        // { str: "21-50 km", val: 1 },
        { str: "Accept", val: "succeeded" },
        { str: "Reject", val: "failed" },
      ],
      headers: [
        {
          sortable: false,
          text: "Name",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "User Type",
          value: "userType",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Proof",
          value: "upgrade",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Amount",
          value: "totalPrice",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Payment State",
          value: "status",
          class: "primary--text title",
        },
      ],
      items: [],
      min: new Date(Date.now() - 315569260000).toISOString().substr(0, 10),
    };
  },
  watch: {
    state(val) {
      if (val.str == "Accept") {
        // console.log(val.str);
        this.isApproved = true;
      } else {
        this.isApproved = false;
      }
    },
  },
  mounted() {
    this.loadTransactions();
  },
  methods: {
    loadTransactions() {
      this.isLoading = true;
      TransactionsApiCalls.getAllDirPayments().then((res) => {
        this.items = res.data.content.data.map((x) => {
          return {
            ...x,
            isDeleteModal: false,
          };
        });
        this.isLoading = false;
      });
    },
    closeDialog() {
      this.isDialog = false;
      this.payment_Id = null;
      this.proof = null;
      this.state = false;
      this.isApproved = false;
    },
    paymentApprovalFn(item) {
      this.isDialog = true;
      this.payment_Id = item.paymentIntent;
      this.proof = item.proof;
      const endDate = new Date(item.created_at.split("T")[0]);
      console.log(endDate.toISOString());
      this.nextBillingDate = `${endDate.getFullYear() + 1}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;
      let [year, month, day] = this.nextBillingDate.split("-");
      let formattedDate = new Date(year, month - 1, day);
      let formattedDateString = formattedDate.toISOString().slice(0,10);
      this.nextBillingDate = formattedDateString;
    },
    async paymentApprovalSubmitFn() {
      this.isApiLoader = true;
      let payload = {
        _id: this.payment_Id,
        proof: this.proof,
        state: this.state.val,
        expireDate: this.nextBillingDate,
      };
      await TransactionsApiCalls.setPaymentApproval(payload).then(async () => {
        let payload2 = {
          snackBarText: `Direct Payments has been updated`,
          snackBarColor: "success",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);

        await TransactionsApiCalls.getAllDirPayments().then((res) => {
          this.isDialog = false;
          this.payment_Id = null;
          this.proof = null;
          this.isApiLoader = false;
          this.items = res.data.content.data.map((x) => {
            return {
              ...x,
              isDeleteModal: false,
            };
          });
        });
      });
      this.loadTransactions();
    },
    getCityApprovalState(isPaymentApproved) {
      if (isPaymentApproved == 0) {
        return "Initial";
      } else if (isPaymentApproved == 1) {
        return "Requested";
      } else if (isPaymentApproved == 2) {
        return "Approved";
      } else if (isPaymentApproved == 3) {
        return "Rejected";
      } else if (isPaymentApproved == 4) {
        return "Paid";
      } else {
        return "Pending";
      }
    },
    getCityApprovalColor(isPaymentApproved) {
      if (isPaymentApproved == 0) {
        return "dark";
      } else if (isPaymentApproved == 1) {
        return "warning";
      } else if (isPaymentApproved == 2) {
        return "success";
      } else if (isPaymentApproved == 3) {
        return "error";
      } else if (isPaymentApproved == 4) {
        return "success";
      } else {
        return "info";
      }
    },
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${month}/${day}/${year}`;
    // },
    // parseDate(date) {
    //   if (!date) return null;

    //   const [month, day, year] = date.split("/");
    //   return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    // }
  },
};
</script>
<style scoped>
/* v-dialog issues */
.v-dialog__content >>> .v-dialog.v-dialog--active {
  overflow: hidden !important;
}
</style>
